import React from "react"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

const StudioPoliciesPage = () => (
  <Layout>
    <Seo title="Parasky Flute Studios Policies" />
    <section className="hero is-primary">
      <div className="container">
        <div className="hero-body is-flex-direction-column is-justify-content-center">
          <h1 className="title is-size-2 is-size-1-desktop has-text-white is-uppercase has-text-centered">
            Studio Policies
          </h1>
        </div>
      </div>
    </section>
    <section>
      <div className="container">

      </div>
    </section>
  </Layout>
)

export default StudioPoliciesPage
